import React from "react";
import Navigation from "./Nav/Navigation";
import CurrentStep from "./CurrentStep";

export default function Details({step, nextStep, handleChange, values, validator, sendEmail}) {
    return (
        <>
        <CurrentStep step={step} />
        <div className="row">
            <div className="col-12">
                <div className="card card--not-hoverable mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Co potrzebujesz?</h4>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('primaryInfo')} className="form-check-input" type="checkbox" id="webpage" name="primaryInfo" value="Strona internetowa" />
                            <label className="form-check-label" htmlFor="webpage">Strona internetowa</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('primaryInfo')} className="form-check-input" type="checkbox" id="graphicProject" name="primaryInfo" value="Projekt graficzny strony"  />
                            <label className="form-check-label" htmlFor="graphicProject">Projekt graficzny strony</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('primaryInfo')} className="form-check-input" type="checkbox" id="logo" name="primaryInfo" value="Logo" />
                            <label className="form-check-label" htmlFor="logo">Logo</label>
                        </div>
                        {validator.current.message('primaryInfo', values.primaryInfo, 'required|array')}
                    </div>
                </div>    
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card card--not-hoverable mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Objętość strony</h4>
                        <div className="form-check">
                            <input onChange={handleChange('size')} className="form-check-input" type="radio" name="size" value="Strona typu One Page" id="onepage" />
                            <label className="form-check-label" htmlFor="onepage">
                                Strona typu "One page"
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={handleChange('size')} className="form-check-input" type="radio" name="size" value="Do 5 podstron" id="smallpage" />
                            <label className="form-check-label" htmlFor="smallpage">
                                Do 5 podstron
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={handleChange('size')} className="form-check-input" type="radio" name="size" value="Więcej niż 5 podstron" id="mediumpage" />
                            <label className="form-check-label" htmlFor="mediumpage">
                                Więcej niż 5 podstron
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={handleChange('size')} className="form-check-input" type="radio" name="size" value="Więcej niż 10 podstron" id="largepage" />
                            <label className="form-check-label" htmlFor="largepage">
                                Więcej niż 10 podstron
                            </label>
                        </div>
                        {validator.current.message('size', values.size, 'required|string')}
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card card--not-hoverable mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Dodatkowe funkcje</h4>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('features')} className="form-check-input" type="checkbox" id="contactForm" name="features" value="Formularz kontaktowy" />
                            <label className="form-check-label" htmlFor="contactForm">Formularz kontaktowy</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('features')} className="form-check-input" type="checkbox" id="additionalLanguage" name="features" value="Dodatkowa wersja językowa" />
                            <label className="form-check-label" htmlFor="additionalLanguage">Dodatkowa wersja językowa</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('features')} className="form-check-input" type="checkbox" id="reservationSystem" name="features" value="System rezerwacji" />
                            <label className="form-check-label" htmlFor="reservationSystem">System rezerwacji</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('features')} className="form-check-input" type="checkbox" id="newsletter" name="features" value="Newsletter" />
                            <label className="form-check-label" htmlFor="newsletter">Newsletter</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('features')} className="form-check-input" type="checkbox" id="blog" name="features" value="Blog" />
                            <label className="form-check-label" htmlFor="blog">Blog</label>
                        </div>
                        <div className="form-check form-switch">
                            <input onChange={handleChange('features')} className="form-check-input" type="checkbox" id="other" name="features" value="Inne" />
                            <label className="form-check-label" htmlFor="other">Inne</label>
                        </div>
                        {validator.current.message('features', values.features, 'array')}
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card card--not-hoverable mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Krótki opis firmy i branży</h4>
                        <p>Opisz proszę czym zajmuje się Twoja firma i jak wygląda Twoja branża. Spokojnie, może być nawet w jednym zdaniu :)</p>
                        <div className="form-group">
                            <textarea 
                            onChange={handleChange('industryDesc')}
                            className="form-control" id="industryDesc" rows="3" placeholder="Krótki opis Twojej firmy i branży"></textarea>
                        </div>
                        {validator.current.message('industryDesc', values.industryDesc, 'string')}
                    </div>
                </div>    
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card card--not-hoverable mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Przykładowe strony</h4>
                        <p>Podaj proszę przykładowe strony konkurencji</p>
                        <div className="form-group">
                            <textarea 
                            onChange={handleChange('samplePages')}
                            className="form-control" id="samplePages" rows="3" placeholder="Przykłady stron, które Ci się podobają"></textarea>
                        </div>
                        {validator.current.message('samplePages', values.samplePages, 'string')}
                    </div>
                </div>    
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card card--not-hoverable mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Uwagi</h4>
                        <div className="form-group">
                            <textarea 
                            onChange={handleChange('comment')}
                            className="form-control" id="comment" rows="3" placeholder="Uwagi, pomysły, pytania..."></textarea>
                        </div>
                        {validator.current.message('comment', values.comment, 'string')}
                    </div>
                </div>    
            </div>
        </div>

        <Navigation step={step} nextStep={nextStep} sendEmail={sendEmail} />
        </>
    )
}