import React from "react";
import NextStep from "./NextStep";
import SendEmail from "./SendEmail";

export default function Navigation({nextStep, sendEmail}) {
    return (
    <>
        <div className="mt-5 d-flex justify-content-end">
            {sendEmail ? <SendEmail sendEmail={sendEmail} /> : <NextStep nextStep={nextStep} />}
        </div>
    </>
    )
}