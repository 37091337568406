import React, { useState } from "react"
import useValidator from "../hooks/useValidator";
import Seo from '../components/Seo'
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import Start from "../components/Brief/Start";
import Details from "../components/Brief/Details";
import Summary from "../components/Brief/Summary";
import Pricing from "../components/Pricing";
import useEmail from "../hooks/useEmail";

export default function Home() {

    const [state, setState] = useState({
        step: 1,
        username: '',
        email: '',
        rodo: false,
        primaryInfo: [],
        size: '',
        features: [],
        industryDesc: '',
        samplePages: '',
        comment: '',
    });

    const { username, email, rodo, primaryInfo, size, features, industryDesc, samplePages, comment } = state;
    const values = {username, email, rodo, primaryInfo, size, features, industryDesc, samplePages, comment};
    const step = state.step;

    const emailHook = useEmail();
    const validator = useValidator();

    // proceed to the next step
    const nextStep = (e) => {
        if(!validator.current.allValid()) {
            return validator.current.showMessages();
        }

        setState({ 
            ...state,
            step: state.step + 1
        });
    }

    // handle field change
    const handleChange = input => e => {
        const { value, checked } = e.target;

        setState(state => {
            if(input === 'primaryInfo' || input === 'features') {
                return {
                    ...state,
                    [input]: checked ? state[input].concat(value) : state[input].filter(ev => ev !== value),
                } 
            }

            return {
                ...state,
                [input]: input === 'rodo' ? checked : value,
            }
        });
    }

    const sendEmail = (e) => {
        nextStep();
        emailHook.send(process.env.GATSBY_EMAILJS_TEMPLATE_ID, values);
        // setIsSending(true);

        // try {
        //     emailjs.send(
        //         process.env.GATSBY_EMAILJS_SERVICE_ID,
        //         process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        //         values, process.env.GATSBY_EMAILJS_USER_ID
        //       )
        //       .then((result) => {
        //         setIsSending(false);
        //         setEmailSent(true);
        //       }, (error) => {
        //         setIsSending(false);
        //         setEmailSent(false);
        //     });
        // } catch(error) {
        //     setIsSending(false);
        //     setEmailSent(false);
        // }
    }

    const showActiveStep = () => {
        switch(step) {
            case 1: {
                return (
                    <Start
                        step={step}
                        nextStep={nextStep}
                        handleChange={handleChange}
                        values={values}
                        validator={validator}
                    />
                )
            }
            case 2: {
                return (
                    <Details
                        step={step}
                        nextStep={nextStep}
                        values={values}
                        handleChange={handleChange}
                        validator={validator}
                        sendEmail={sendEmail}
                    />
                )
            }
            case 3: {
                return (
                    <Summary emailSent={emailHook.emailSent} isSending={emailHook.isSending} hasError={emailHook.hasError} />
                )
            }
            default: {
                return (
                    <Start
                        step={step}
                        nextStep={nextStep}
                        handleChange={handleChange}
                        values={values}
                        validator={validator}
                    />
                )
            }
        }
    }

    return (
        <>
        <Seo title="Wycena strony internetowej, grafiki i innych usług" description="Potrzebujesz strony internetowej, grafiki, logotypu albo innych usług programisy? Wypełnij formularz i wyceń swoje potrzeby." />
        <Layout title="Wycena">
        <section id="brief" className="section">
            <div className="container">
                {step === 1 && <SectionTitle title="Brief" subtitle="Na podstawie danych z tego formularza będę mógł przygotować dla Ciebie wycenę strony internetowej. Nie martw się, zajmie to dosłownie chwilę." />}

                <div className="row gy-5 gy-lg-0">
                    <div className="col-12">
                        {showActiveStep()}
                    </div>
                </div>
            </div>
        </section>
        {
            step === 1 && (
                <>
                <section className="section section--with-bg py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12" data-aos="fade-up">
                                <p>Poniższe ceny są tylko przykładem. Dokładna wycena Twojej strony zależy od specyfikacj i wymagań projektu. Każdy projekt jest inny i każdy wymaga indywidualnego podejścia. Wraz z oczekiwaniami i wymaganiami rośnie cena, ale nie martw się. Zrobię dla Ciebie szybką, responsywną i estetyczną stronę w atrakcyjnej cenie.</p>
                                <p>Chcesz dowiedzieć się jak wycenię Twoj prójekt? Proszę wypełnij powyższy brief.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <SectionTitle title="Cennik" subtitle="Poniższe ceny są tylko orientacyjne. Każdy projekt jest wyceniony indywidualnie w zależności od potrzeb." />
                        <Pricing />
                    </div>
                </section>
                </>
            )
        }
        </Layout>
        </>
    )
}