import React from "react";
import Navigation from "./Nav/Navigation";
import CurrentStep from "./CurrentStep";

export default function Start({step, nextStep, handleChange, values, validator}) {
    return (
        <>
        <CurrentStep step={step} />
        <div className="card card--not-hoverable">
            <div className="card-body">
                <h4 className="card-title mb-3">Wycena strony internetowej</h4>
                {/* <input type="hidden" name="contact-form" value="contact-form" /> */}
                <div className="row mb-2">
                    <div className="col-md-6 form-group has-validation"> 
                        <input 
                        onChange={handleChange('username')} 
                        onKeyUp={()=>validator.current.showMessageFor('username')}
                        type="text" name="username" className="form-control" id="username" placeholder="Imię" value={values.username} />
                        {validator.current.message('username', values.username, 'required|alpha_space')}
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input 
                        onChange={handleChange('email')} 
                        onBlur={()=>validator.current.showMessageFor('email')}
                        type="email" className="form-control" name="email" id="email" placeholder="E-mail" value={values.email} />
                        {validator.current.message('email', values.email, 'required|email')}
                    </div>
                </div>

                <div className="form-check has-validation">
                    <input 
                        onChange={handleChange('rodo')}
                        className="form-check-input"
                        type="checkbox" name="rodo" id="rodo" checked={values.rodo} />
                    <label className="form-check-label contact-form__label contact-form__label--is-small" htmlFor="rodo">
                    Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym formularzu w celu udzielenia odpowiedzi na przesłane zapytanie przez administratora strony Macieja Tymosiaka.
                    </label>
                    {validator.current.message('rodo', values.rodo, 'required|accepted')}
                </div>
            </div>
        </div>

        <Navigation step={step} nextStep={nextStep} />
        </>
    )
}