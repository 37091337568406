import React from 'react';
import {Link} from 'gatsby'
import Alert from '../Alert';
import Spinner from '../Spinner';
import { isBrowser } from '../../utils'; 

export default function Summary({emailSent, isSending, hasError}) {
  if(!isBrowser) return;

  const message = (emailSent && !hasError) ? 'Wiadomość została wysłana 🎉 Niedługo się odezwę 😎' : 'Ups 😥 Coś poszło nie tak i wiadomość nie została wysłana. Proszę spróbować ponownie.',
    alertClass = (emailSent && !hasError) ? 'alert-success' : 'alert-danger';

  const statusBtn = emailSent 
  ? <Link key="1" className='btn btn-secondary mt-3' to="/" title="Powrót" >Wróć na stronę główną</Link>
  : <button key="1" className='btn btn-secondary mt-3' onClick={() => window.location.reload(false)}>Wyślij ponownie</button>;

  const showStatus = () => {
    if(isSending) {
      return <Spinner />
    }
    
    return ([<Alert key="0" alertClass={alertClass} message={message} />, statusBtn]);
  }

  return (
    <>
    <div className="card card--not-hoverable">
        <div className="card-body">
          {emailSent && <h2 className="mb-3">Dziękuję za kontakt</h2>}
          {showStatus()}
        </div>
    </div>
    </>
  )
}
